import * as React from 'react';
import { ApplicationStyle, SearchService, StoreService, style } from '@eir/core';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { initFireStore } from '../../FireStore/fireStoreInit';
import { ArticlesStore } from '../../stores/ArticlesStore';
import { CategoriesStore } from '../../stores/CategoriesStore';
import { RootStore } from '../../stores/RootStore';
import { SectionsStore } from '../../stores/SectionsStore';
import { UIStore } from '../../stores/UIStore';
import { Layout } from '../Layout/Layout';
import { MainLoader } from '../Loaders/MainLoader';
import './App.css';

// application theme context to be used through the application
export const ThemeContext: React.Context<ApplicationStyle> = React.createContext(style.demo);

// application style to be used outside the context; static import
export let appColors: ApplicationStyle = style.demo;

type PathParamsType = {
  projectId: string;
  search: string;
  searchText: string;
};

type Props = RouteComponentProps<PathParamsType> & {
  rootStore?: RootStore;
};

@observer
class AppComponent extends React.Component<Props> {
  searchOnAppLoad = false;

  @observable
  isAppReady = false;

  @observable
  theme = style.demo;

  @observable
  searchService: SearchService;

  @observable
  rootStore: RootStore;

  @observable
  uiStore: UIStore = new UIStore();

  @observable
  categoriesStore: CategoriesStore;

  @observable
  sectionsStore: SectionsStore;

  @observable
  articlesStore: ArticlesStore;

  @observable
  storeService: StoreService;

  migrated = [
    { id: 'ambulans_ostergotland', redirect: 'ambulans-ostergotland.infosynk.se' },
    { id: 'falu_kommun', redirect: 'falu-kommun.infosynk.se' },
    { id: 'saters_kommun', redirect: 'saters-kommun.infosynk.se' },
  ];

  redirectMigrated(): void {
    const pathElems = window.location.pathname.split('/');
    if (pathElems.length === 0) {
      return;
    }

    const id = pathElems[1];
    const redirect = this.migrated.find((i) => i.id === id);
    if (redirect) {
      console.log(window.location.href);
      const path = new URL(window.location.href);
      path.host = redirect.redirect;
      console.log(path.toString());
      window.location.assign(path.toString());
    }
  }

  componentDidMount(): void {
    this.redirectMigrated();
    this.initFirebase();
    this.observeDraftMode();
  }

  initFirebase(): void {
    const { pathname } = this.props.location;

    initFireStore.then(({ auth, storage, fireStore }) => {
      this.initStores(storage, auth, fireStore);

      auth.onAuthStateChanged((user) => {
        this.initStores(storage, auth, fireStore);
      });

      // eslint-disable-next-line
      const styles = style[this.rootStore.projectId!];
      this.theme = styles || style.demo;
      appColors = styles || style.demo;

      // FIXME: this is not ok - needs to be refactored and handled in different way
      this.searchOnAppLoad = pathname.search('/search') !== -1;
      if (this.searchOnAppLoad) {
        this.searchService
          .setupSearchIndex(this.storeService)
          .then((_) => console.log('Search index is loaded.'))
          .then(() => (this.isAppReady = true));
      } else {
        this.isAppReady = true;
      }
    });
  }

  render(): React.ReactNode {
    return this.isAppReady ? (
      <ThemeContext.Provider value={this.theme}>
        <Provider uiStore={this.uiStore} rootStore={this.rootStore}>
          <React.Fragment>
            <Layout
              categoriesStore={this.categoriesStore}
              sectionsStore={this.sectionsStore}
              articlesStore={this.articlesStore}
            />
          </React.Fragment>
        </Provider>
      </ThemeContext.Provider>
    ) : (
      <div>
        <MainLoader />
      </div>
    );
  }

  private observeDraftMode(): void {
    this.uiStore.isInDraftMode.observe(() =>
      initFireStore.then(({ storage, auth, fireStore }) => this.initStores(storage, auth, fireStore)),
    );
  }

  private initStores(
    storage: firebase.storage.Storage,
    auth: firebase.auth.Auth,
    fireStore: firebase.firestore.Firestore,
  ): void {
    const { projectId } = this.props.match.params;
    // eslint-disable-next-line
    this.storeService = new StoreService(fireStore as any, projectId, this.uiStore.draftMode, auth.currentUser);
    this.rootStore = new RootStore(this.storeService, storage, auth, projectId, this.uiStore.draftMode);
    this.searchService = new SearchService(this.storeService, !this.searchOnAppLoad);
    this.articlesStore = new ArticlesStore(this.storeService, this.searchService);
    this.categoriesStore = new CategoriesStore(this.storeService);
    this.sectionsStore = new SectionsStore(this.storeService);
  }
}

export const App = withRouter(AppComponent);
